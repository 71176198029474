import React from "react"
import Layout from "../components/layout"
import MarquesList from "../components/MarquesList"
import GridVoitures from "../components/GridVoitures"
import ActualiteGrid from "../components/ActualiteGrid"
import SEO from "../components/seo"
import { Link } from "gatsby"
import AdSense from "react-adsense"
import Img from "gatsby-image"

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: marques } = data.allTaxonomyTermMarques
    const { edges: actualites } = data.allNodeActualites
    const { edges: modelsHome } = data.allTaxonomyTermModels

    const voitureNew = []
    const voiturePopulaire = []
    const voitureProchainement = []

    modelsHome.map(model => {
      if (model.node.field_promu_home.includes("new")) {
        voitureNew.push(model)
      }
      if (model.node.field_promu_home.includes("populaire")) {
        voiturePopulaire.push(model)
      }
      if (model.node.field_promu_home.includes("prochainement")) {
        voitureProchainement.push(model)
      }
    })

    const imgs = []

    const loadingImage =
      "data:image/gif;base64,\
      R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="

    return (
      <Layout>
        <SEO title="Gamme des voitures neuves au maroc" />
        <section class="section section-header bg-primary overlay-primary text-white pb-2 pb-lg-8">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-md-8 text-center">
                <p className="display-1 font-weight-light mb-3">
                  <span className="font-weight-bolder ml-3">Mauto</span>
                  <span className="pixel-pro-badge font-weight-bolder text-primary">
                    Neuf
                  </span>
                </p>
                <h1 class="h3">
                  Prix et fiche technique des voitures neuves au maroc
                </h1>
              </div>
            </div>
          </div>
          <figure class="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg
              class="fill-white"
              enable-background="new 0 0 1504 131.3"
              viewBox="0 0 1504 131.3"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m877.8 85c139.5 24.4 348 33.5 632.2-48.2-.2 32.5-.3 65-.5 97.4-505.9 0-1011.6 0-1517.5 0 0-33.3 0-66.7 0-100.1 54.2-11.4 129.5-23.9 220-28.2 91-4.3 173.6 1 307.4 18.6 183.2 24.2 295.2 49.4 358.4 60.5z"></path>
            </svg>
          </figure>
        </section>

        <section className="container mt-n8 mt-md-n12 mt-lg-n10 z-2 section section-lg">
          <MarquesList marques={marques} />
        </section>
        <section className="section section-lg line-bottom-lightmt-2 mt-2">
          <div className="container mt-n8 mt-md-n9 mt-1 z-2 ">
            <h3>Actualité à la une</h3>
            <ActualiteGrid title="Actualité à la une" actualites={actualites} />
          </div>
        </section>
        <section className="section bg-dark overflow-hidden z-2  pt-2 mb-0">
          <div className="container">
            <h5 className="h4 text-white">Voitures populaires</h5>
            <GridVoitures voitures={voiturePopulaire} colmd={3} />
          </div>
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    allNodeActualites(limit: 6, sort: { fields: created, order: DESC }) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          field_description
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }

    allTaxonomyTermModels(
      filter: {
        field_promu_home: { in: ["new", "populaire", "prochainement"] }
      }
    ) {
      edges {
        node {
          path {
            alias
          }
          field_isnew
          field_ispromo
          field_promu_home
          name
          id
          relationships {
            field_marques {
              path {
                alias
              }
            }
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          field_prix_min
          field_prix_max
          field_chevaux_fiscale
          field_carburant
          field_chevaux
          field_isnew
          field_ispromo
        }
      }
    }
    allTaxonomyTermMarques {
      edges {
        node {
          path {
            alias
          }
          id
          name
          relationships {
            field_image {
              uri {
                value
                url
              }
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
